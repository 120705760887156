@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    outline: none !important;
  }
  html {
    font-family: Poppins, sans-serif;
  }
}
@layer utilities {
  .card {
    box-shadow: 0px 1px 18px 0px #0000001f, 0px 6px 10px 0px #00000024,
      0px 3px 5px -1px #06569933;
    @apply py-4 px-4 rounded-md;
  }
  .btn-light_primary {
    @apply px-6 py-1.5 border-2 border-light_primary bg-light_primary text-white rounded lg:text-base sm:text-sm text-xs;
  }
  .btn-outline {
    @apply px-6 py-1.5 font-semibold border-2 border-theme text-theme rounded lg:text-base sm:text-sm text-xs;
  }
  .btn-outline-disabled {
    @apply px-6 py-1.5 font-semibold border-2 border-secondary_grey text-secondary_grey rounded lg:text-base sm:text-sm text-xs;
  }
  .btn {
    @apply px-6 py-1.5 border-2 border-black rounded lg:text-base sm:text-sm text-xs;
  }
  .text-red {
    color: red;
  }
  .btn-theme {
    @apply px-6 py-1.5 border-2 border-theme bg-theme text-white rounded lg:text-base sm:text-sm text-xs;
  }
  .plus-btn {
    @apply flex items-center uppercase border bg-secondary_grey lg:text-base sm:text-sm text-xs shadow-[0px_1px_18px_0px_#0000001F_0px_6px_10px_0px_#00000024_0px_3px_5px_-1px_#06569933] rounded-full px-4 py-3 pb-2.5;
  }
  .shadow-toggle {
    box-shadow: 0px 1px 3px 0px #0000001f, 0px 1px 1px 0px #00000024,
      0px 2px 1px -1px #00000033;
  }

  .model {
    @apply backdrop-blur-sm bg-black/40 flex items-center justify-center w-full fixed top-0 left-0 right-0 z-[99] mx-auto h-screen !my-0 px-4;
  }
  .model_right {
    @apply backdrop-blur-sm bg-black/40 flex justify-end w-full fixed top-0 left-0 right-0 z-[99] h-screen !my-0;
  }

  ::-webkit-scrollbar {
    @apply w-2.5 h-2.5;
  }

  ::-webkit-scrollbar-track {
    @apply bg-theme/10 rounded-full;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-theme/30 rounded-full;
  }
  .messageContent .messageText {
    @apply rounded-xl;
  }

  /* .right-shadow {
    @apply after:w-full after:h-full after:bg-white after:absolute after:top-0 after:left-0 after:shadow-[0px_4px_4px_0px_#00000040] after:scale-95 after:translate-x-3 after:z-[-1];
  } */
  .stepper > :not([hidden]) ~ :not([hidden]) {
    @apply relative before:absolute before:w-[1px] xl:before:h-8 before:h-5 before:bg-gray-400 before:top-0 xl:before:left-4 before:left-3 xl:before:translate-y-[-115%] before:translate-y-[-125%];
  }
}

.bg-linear {
  background: linear-gradient(to top, white, transparent);
}

.ql-toolbar.ql-snow {
  @apply border-none bg-light_primary_shades rounded-md mb-3 after:content-[];
}

.ql-container.ql-snow {
  @apply border-secondary_grey rounded-md;
}

.quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #0000003b;
}

.ql-snow .ql-tooltip {
  @apply z-10 !left-0;
}

.responsive_base_text {
  @apply lg:text-base sm:text-sm text-xs;
}

.table_responsive_text {
  @apply sm:text-sm text-xs;
}

.chat-quill-container .ql-container .ql-editor {
  @apply pr-12;
}
